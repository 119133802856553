import { useState } from 'preact/hooks';
import { addWeeks, subWeeks } from 'date-fns';

// Custom hook for handling week navigation
function useWeekNavigation(initialWeekStart) {
  const [currentWeekStart, setCurrentWeekStart] = useState(initialWeekStart);

  // Navigate to the next week
  const nextWeek = () => {
    setCurrentWeekStart((current) => addWeeks(current, 1));
  };

  // Navigate to the previous week
  const prevWeek = () => {
    setCurrentWeekStart((current) => subWeeks(current, 1));
  };

  return { currentWeekStart, nextWeek, prevWeek };
}

export default useWeekNavigation;
