import { useState, useEffect } from 'preact/hooks';
import { services } from '../utils/commonUtils';

function useBookingSelection(initialBookingFor = "Adult", initialOption = "New Patient") {
  const [bookingFor, setBookingFor] = useState(initialBookingFor);
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [selectedService, setSelectedService] = useState(null);
  const [userHasMadeASelection, setUserHasMadeASelection] = useState(false); // Flag to track if user has initiated a selection

  useEffect(() => {
    // Apply logic only if the user has initiated a selection
    if (userHasMadeASelection) {
      const availableServices = services[selectedOption]?.[bookingFor] || [];
      const isServiceAvailable = availableServices.some(service => service.id === selectedService);

      if (isServiceAvailable) {
        setSelectedService(selectedService);
      } else if (availableServices.length > 0) {
        setSelectedService(availableServices[0].id);
      } else {
        setSelectedService(null);
      }
    }
  }, [bookingFor, selectedOption, selectedService, userHasMadeASelection]);

  const handleBookingForChange = (event) => {
    setBookingFor(event.target.value);
    setUserHasMadeASelection(true); // Mark that the user has initiated a selection
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setUserHasMadeASelection(true); // Mark that the user has initiated a selection
  };

  const handleServiceSelect = (serviceId) => {
    setSelectedService(serviceId);
    setUserHasMadeASelection(true); // Mark that the user has initiated a selection
  };

  return {
    bookingFor,
    selectedService,
    selectedOption,
    handleBookingForChange,
    handleServiceSelect,
    handleOptionChange,
    userHasMadeASelection, // Optionally expose this if you need it outside
  };
}

export default useBookingSelection;
