// src/contexts/LocationContext.js
import { useState, useContext } from 'preact/hooks';
import { createContext } from 'preact';


const LocationContext = createContext();

export const useLocationContext = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const [locationData, setLocationData] = useState(null);
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [locationPhone, setLocationPhone] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [noSlots, setNoSlots] = useState(false);
  const [locationZip, setLocationZip] = useState(null);
  const [locations, setLocations] = useState({});
  const [locationMap, setLocationsMap] = useState('');

  // Function to fetch and set location data, could be triggered from anywhere
  const fetchLocationData = (apiUrl, setIsSlotsLoading) => {
    setIsSlotsLoading(true);
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const locationData = data.locations?.[0]; 

        if (locationData?.is_under_maintenance || locationData?.is_replication_offline) {
          setUnderMaintenance(true);
          setLocationPhone(locationData.phone_number);
          setIsSlotsLoading(false); // Stop loading as we're not fetching slots
          return; // Exit early
        }
        // Assuming data.locations is the array you're interested in
        const slots = data.locations?.[0]?.dates || [];
        const areAllSlotsEmpty = slots.every(
          (dateObj) => dateObj.slots.length === 0
        );
        setAvailableSlots(slots);
        if (areAllSlotsEmpty) {
          // setIsSlotsLoading(false);
          setNoSlots(true);
        }
      })
      .catch((error) => {
        setIsSlotsLoading(false);
      })
      .finally(() => {
        setIsSlotsLoading(false);
      });
  };

  const fetchLocations = async (locationId) => {
    try {
      const response = await fetch(
        "https://mintproxyninja.com/api/locations?is_active=1",
        {
          method: "GET", // This line is optional since GET is the default method for fetch
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const locations = await response.json();
      const locationsMapZip = {};
      const locationsMap = {};

      locations.forEach((location) => {
        locationsMapZip[location.id] = location.zip;
        locationsMap[location.id] = location.name;
      });

      setLocationZip(locationsMapZip[locationId]);
      setLocationsMap(locationsMap);
      if (locations) {
        setLocations(locations); // Store the full location objects
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  return (
    <LocationContext.Provider value={{
      locationData,
      setLocationData,
      underMaintenance,
      setUnderMaintenance,
      locationPhone,
      setLocationPhone,
      availableSlots,
      setAvailableSlots,
      noSlots,
      setNoSlots,
      fetchLocationData,
      locationZip,
      locations,
      fetchLocations,
      locationMap,
    }}>
      {children}
    </LocationContext.Provider>
  );
};
