import { format, parseISO } from "date-fns";

export const convertToStandardTime = (militaryTime) => {
  const [hours, minutes] = militaryTime.split(":");
  const hoursInt = parseInt(hours, 10);
  const period = hoursInt >= 12 ? "PM" : "AM";
  const standardHours = ((hoursInt + 11) % 12) + 1; // Converts "00" to "12" and keeps "12" as "12"
  return `${standardHours}:${minutes} ${period}`;
};

// Define the services based on the selection
export const services = {
  "New Patient": {
    Adult: [
      {
        id: "cleaning",
        title: "New Patient - Cleaning",
        imageUrl:
          "https://res.cloudinary.com/dvi15w991/image/upload/v1711052010/OnlineBooking/cleaning_af9nee_kmdmfe.png",
      },
      {
        id: "emergency",
        title: "New Patient - Emergency/Tooth Pain",
        imageUrl:
          "https://res.cloudinary.com/dvi15w991/image/upload/v1711052437/OnlineBooking/emergency_tm53dt_c0w1wr.png",
      },
    ],
    Child: [
      {
        id: "childCleaning",
        title: "New Patient - Child Cleaning",
        imageUrl:
          "https://res.cloudinary.com/dvi15w991/image/upload/v1711052010/OnlineBooking/cleaning_af9nee_kmdmfe.png",
      },
    ],
  },
  "Existing Patient": {
    Adult: [
      {
        id: "cleaning",
        title: "Existing Patient - Cleaning",
        imageUrl:
          "https://res.cloudinary.com/dvi15w991/image/upload/v1711052010/OnlineBooking/cleaning_af9nee_kmdmfe.png",
      },
      {
        id: "emergency",
        title: "Existing Patient - Emergency/Tooth Pain",
        imageUrl:
          "https://res.cloudinary.com/dvi15w991/image/upload/v1711052437/OnlineBooking/emergency_tm53dt_c0w1wr.png",
      },
      // {
      //   id: "fillings",
      //   title: "Treatment - Fillings",
      //   imageUrl:
      //     "https://jarvis-scheduling.s3.amazonaws.com/uploads/default.svg",
      // },
    ],
    Child: [
      {
        id: "childCleaning",
        title: "Existing Patient - Child Cleaning",
        imageUrl:
          "https://res.cloudinary.com/dvi15w991/image/upload/v1711052010/OnlineBooking/cleaning_af9nee_kmdmfe.png",
      },
    ],
  },
};

export const currentYear = new Date().getFullYear();

const apiEndpointMap = {
  "New Patient_Adult_cleaning": "available-slots/new",
  "New Patient_Adult_emergency": "available-slots/emergency",
  "Existing Patient_Adult_emergency": "available-slots/emergency",
  "Existing Patient_Adult_cleaning": "available-slots/recall",
  "New Patient_Child_childCleaning": "available-slots/child",
  "Existing Patient_Child_childCleaning": "available-slots/recall_child",
};

// utils/apiEndpoints.js
export const getApiEndpoint = (selectedOption, bookingFor, selectedService) => {
  console.log("selectedOption", selectedOption, "bookingFor", bookingFor, "selectedService", selectedService);
  const key = `${selectedOption}_${bookingFor}_${selectedService}`;
  return apiEndpointMap[key] || null; // Provide a default or handle undefined keys as needed
};

export const searchByZipCode = async (zipCode) => {
  const GOOGLE_ZIP_NO_RESULTS = "ZERO_RESULTS";
  const apiKey = "AIzaSyDsNOm_6IdU7oPFScvaAsLgZA2VCOni_K4"; // Use your actual API key

  if (!zipCode || zipCode.length <= 4) {
    return;
  }

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;
  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === GOOGLE_ZIP_NO_RESULTS) {
      return null;
    }

    const { lat, lng } = data.results[0].geometry.location;

    return { lat, lng };
  } catch (error) {
    console.error("Error fetching location data:", error);
    return null;
  }
};

export const formatDate = (dateStr) => {
  const parsedDate = parseISO(dateStr);
  return format(parsedDate, "EEE, MMM dd");
};

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}
