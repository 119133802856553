// useSlotSelection.js
import { useState } from 'preact/hooks';

export function useSlotSelection () {
  const [selectedSlots, setSelectedSlots] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDateSlot, setSelectedDateSlot] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);


  return {
    selectedSlots,
    setSelectedSlots,
    selectedSlot,
    selectedDateSlot,
    setSelectedDateSlot,
    selectedTimeSlot,
    setSelectedTimeSlot,
    setSelectedSlot
  };
};
