import { useState } from "preact/hooks";

// Custom hook for managing submission and loading states
export function useSubmissionStates() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmissionReady, setIsSubmissionReady] = useState(false);
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  const [isScheduleEnabled, setIsScheduleEnabled] = useState(false);

  return {
    isSubmitted,
    setIsSubmitted,
    isProcessing,
    setIsProcessing,
    isSubmissionReady,
    setIsSubmissionReady,
    isSlotsLoading,
    setIsSlotsLoading,
    isScheduleEnabled,
    setIsScheduleEnabled,
  };
}
