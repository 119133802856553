// src/App.js
import OnlineBookingContainer from "./OnlineBookingContain";
import { LocationProvider } from "../context/LocationContext";

function App() {
  return (
    <LocationProvider>
      <OnlineBookingContainer />
    </LocationProvider>
  );
}

export default App;
