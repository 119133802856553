import { h } from "preact";

const ProcessingComponent = ({height, msg}) => {
  const spinnerStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    borderLeftColor: "#09f",
    animation: "spin 1s ease infinite",
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: height,
    textAlign: "center",
  };

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex-grow pt-6 mx-auto lg:w-1/2 sm:p-6">
        <div className="max-w-screen-sm mx-auto">
          <div className="px-6 py-10 w-full text-center bg-color">
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              {/* <p>Processing your request, please stand by...</p> */}
              <p>{msg}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingComponent;
