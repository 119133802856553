import { useState, useEffect } from "preact/hooks";
import { Suspense, lazy } from "preact/compat";
import { format, add, startOfWeek } from "date-fns";
const Schedule = lazy(() => import("./Schedule"));
const ThankYou = lazy(() => import("./ThankYou"));
const RenderDateTimeSelection = lazy(() =>
  import("./components/RenderDateTimeSelection")
);
import ProcessingComponent from "./components/ProcessingComponent";
import { services, getApiEndpoint } from "./utils/commonUtils";
import useHover from "./hooks/useHoverStates";
import useWeekNavigation from "./hooks/useWeekNavigation";
import useBookingSelection from "./hooks/useBookingSelection";
import { useSubmissionStates } from "./hooks/useSubmissionStates";
import { useSlotSelection } from "./hooks/useSlotSelection";
import { useLocationContext } from "./context/LocationContext";

// function getNextSaturday() {
//   const today = new Date();
//   const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

//   // Calculate days until next Saturday
//   const daysUntilSaturday = 6 - dayOfWeek;

//   // Calculate milliseconds until next Saturday
//   const millisecondsUntilSaturday = daysUntilSaturday * 24 * 60 * 60 * 1000;

//   // Calculate the date of next Saturday
//   const nextSaturday = new Date(today.getTime() + millisecondsUntilSaturday);

//   return nextSaturday;
// }

function Details({ locationId, onIsNextEnabled, isSubmitEnabled }) {
  // Determine the initial week start based on the current day
  const today = new Date();
  const isWeekend = today.getDay() === 0 || today.getDay() === 6; // Sunday or Saturday
  let initialWeekStart = startOfWeek(today, { weekStartsOn: 1 });

  if (isWeekend) {
    // If it's weekend, set initialWeekStart to next Monday
    initialWeekStart = add(initialWeekStart, { weeks: 1 });
  }

  // const [selectedOption, setSelectedOption] = useState("New Patient"); // Set default to New Patient
  const {
    bookingFor,
    selectedService,
    handleBookingForChange,
    handleServiceSelect,
    selectedOption,
    handleOptionChange,
  } =useBookingSelection();
  const { currentWeekStart, nextWeek, prevWeek } =
    useWeekNavigation(initialWeekStart);
  const {
    selectedSlots,
    setSelectedSlots,
    selectedSlot,
    selectedDateSlot,
    setSelectedDateSlot,
    selectedTimeSlot,
    setSelectedTimeSlot,
    setSelectedSlot,
  } = useSlotSelection();
  const [goToSchedule, setGoToSchedule] = useState(false);
  const [scheduleData, setScheduleData] = useState({});
  const {
    isSubmitted,
    setIsSubmitted,
    isProcessing,
    setIsProcessing,
    isSubmissionReady,
    setIsSubmissionReady,
    isSlotsLoading,
    setIsSlotsLoading,
    isScheduleEnabled,
    setIsScheduleEnabled,
  } = useSubmissionStates();
  const [locID, setLocId] = useState(null);
  const { hoveredService, handleMouseEnter, handleMouseLeave } = useHover();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
    const { 
    setUnderMaintenance,
    noSlots,
    setNoSlots,
    fetchLocationData,
  } = useLocationContext();

  // const handleOptionChange = (event) => {
  //   const newValue = event.target.value;
  //   setSelectedOption(newValue);
  // };

  // Determine which services to display
  const displayedServices = services[selectedOption][bookingFor];

  useEffect(() => {
    const isNextEnabled =
      selectedOption &&
      bookingFor &&
      selectedService &&
      selectedSlots?.length > 0;
    onIsNextEnabled(isNextEnabled);
    isSubmitEnabled(isSubmitted);
    setNoSlots(false);
    setUnderMaintenance(false); // Reset under maintenance flag
    // if (locationId && selectedService) {
    //   setIsSlotsLoading(true);
    //   const endpoint = getApiEndpoint(selectedOption, bookingFor);
    //   const startDate = format(currentWeekStart, "yyyy-MM-dd");
    //   const endDate = format(add(currentWeekStart, { days: 4 }), "yyyy-MM-dd"); // 5 days later

    //   const apiUrl = `https://mintproxyninja.com/api/${endpoint}?start_date=${startDate}&end_date=${endDate}&locations[]=${locationId}`;

    // // Fetch location data using the function from context
    //   fetchLocationData(apiUrl, setIsSlotsLoading );

    // }
    if (selectedOption && bookingFor && selectedService && locationId) {
      setIsSlotsLoading(true);
      const endpoint = getApiEndpoint(selectedOption, bookingFor, selectedService);
  
      if (endpoint) {
        const startDate = format(currentWeekStart, "yyyy-MM-dd");
        const endDate = format(add(currentWeekStart, { days: 4 }), "yyyy-MM-dd");
        const apiUrl = `https://mintproxyninja.com/api/${endpoint}?start_date=${startDate}&end_date=${endDate}&locations[]=${locationId}`;
  
        // Fetch location data using the function from context or directly
        fetchLocationData(apiUrl, setIsSlotsLoading);
      }
    }
  }, [
    locationId,
    selectedService,
    selectedOption,
    bookingFor,
    currentWeekStart,
    // selectedSlots,
    isSubmitted,
  ]); // Now also depends on selectedOption and bookingFor

  const isNextEnabled =
    selectedOption &&
    bookingFor &&
    selectedService &&
    selectedSlots?.length > 0;

  const handleNextClick = () => {
    if (isNextEnabled) {
      console.log("Navigate to Schedule");
      setGoToSchedule(true);
      setIsScheduleEnabled(true);
    }
  };

  const handleBackClick = () => {
    setGoToSchedule(false);
    setIsScheduleEnabled(false);
  };

  const handleScheduleCallbackData = async (data) => {
    setScheduleData(data);
  };

  useEffect(() => {
    let isFormComplete =
      scheduleData?.firstName?.length > 0 &&
      scheduleData?.lastName?.length > 0 &&
      scheduleData?.emailValid &&
      scheduleData?.gender?.length > 0 &&
      scheduleData?.birthDate?.length === 10 &&
      scheduleData?.mobilePhone?.length >= 14;

    // If insurance is yes, check that insuranceCarrierName is not empty
    if (scheduleData?.hasInsurance === "yes") {
      isFormComplete =
        isFormComplete && scheduleData?.insuranceCarrierName?.length > 0;
    }
    if (scheduleData?.insuranceCarrierName === "Other Carrier") {
      isFormComplete =
        isFormComplete && scheduleData?.otherInsuranceCarrierName?.length > 0;
    }

    // Assuming you have a state called setIsSubmitEnabled to control the enabled state of the Submit button
    setIsSubmissionReady(isFormComplete);
  }, [scheduleData]);

  // Function to submit all collected data
  const submitAllData = async () => {
    // Assuming scheduleData is your state that includes all the form data
    const {
      hasInsurance,
      insuranceCarrierName,
      insurancePhoneNumber,
      groupNumber,
      memberIdNumber,
      employerName,
      policyHolderFirstName,
      policyHolderLastName,
      policyHolderDob,
      appointmentNotes,
      birthDate,
      email,
      firstName,
      gender,
      lastName,
      mobilePhone,
      isPatientPolicyHolder,
      otherInsuranceCarrierName,
      ...restOfData
    } = scheduleData;

    // Use the first selected slot for submission
    const firstSelectedSlot = selectedSlot?.length > 0 ? selectedSlot[0] : null;

    let slot_type;

    if (selectedOption === "New Patient" && bookingFor === "Adult" && selectedService === "cleaning") {
      slot_type = "new";
    } else if (selectedOption === "New Patient" && bookingFor === "Child" && selectedService === "childCleaning") {
      slot_type = "child";
    } else if (selectedOption === "Existing Patient" && bookingFor === "Adult" && selectedService === "cleaning") {
      slot_type = "recall";
    } else if (selectedOption === "Existing Patient" && bookingFor === "Child" && selectedService === "childCleaning") {
      slot_type = "recall_child";
    } else if (selectedOption === "Existing Patient" && bookingFor === "Adult" && selectedService === "emergency") {
      slot_type = "emergency";
    } else if (selectedOption === "New Patient" && bookingFor === "Adult" && selectedService === "emergency") {
      slot_type = "emergency";
    }

    // Construct the note including the appointmentNotes and insurance information
    const grpNumb = groupNumber ? `Group Number: ${groupNumber}\n` : "Group Number: none\n";
    const memberIdNumb = memberIdNumber ? `Member ID Number: ${memberIdNumber}\n` : "Member ID Number: none\n";
    const employer = employerName ? `Employer Name: ${employerName}\n` : "Employer Name: none\n";
    const insuranceNum = insurancePhoneNumber ? `Insurance Phone Number: ${insurancePhoneNumber}\n` : "Insurance Phone Number: none\n";
    const otherInsuranceCarrier = otherInsuranceCarrierName ? `Other Insurance Carrier Name: ${otherInsuranceCarrierName}\n` : "";

    let note = `Added via [[Online]]\nDate Added: ${
      new Date().toISOString().split("T")[0]
    } ${new Date().toLocaleTimeString()}\n`;

    if (hasInsurance === "yes") {
      note += `Insurance: Yes\n Carrier Name: ${insuranceCarrierName}\n ${otherInsuranceCarrier} ${grpNumb} ${memberIdNumb} ${insuranceNum} ${employer}`;
    }
    if (hasInsurance === "no") {
      note += `Insurance: No\n`;
    }

    if (isPatientPolicyHolder === "no") {
      note += `Other Policy Holder Information:\n PolicyHolderFirstName ${policyHolderFirstName}\n PolicyHolderLastName ${policyHolderLastName}\n PolicyHolderDOB ${policyHolderDob}\n`;
    }
    note += `PT Notes: ${appointmentNotes}\nFirst: ${firstName}\nLast: ${lastName}\nEmail: ${email}\nGender: ${gender}\nDOB: ${birthDate}\nPhone: ${mobilePhone}\n[[Online]]`;

    const fullData = {
      Gender: gender,
      Note: note,
      apt_datetime: `${selectedDateSlot} ${selectedTimeSlot}`,
      birth_date: birthDate,
      email: email,
      first_name: firstName,
      last_name: lastName,
      op: firstSelectedSlot, // Include only the first selected slot
      slot_type: slot_type, // Add the mapped slot_type
      WirelessPhone: mobilePhone,
      // PriProv: 95, // Assuming 95 is the provider ID
      // ProvNum: 95, // Assuming 95 is the provider ID
      // Include insurance info in the payload if present
      ...(hasInsurance === "yes" && {
        insurance: { insuranceName: insuranceCarrierName },
      }),
    };

    if (locationId) {
      try {
        setIsProcessing(true);
        const response = await fetch(
          `https://mintproxyninja.com/api/${locID}/appointments`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(fullData),
          }
        );

        const datas = await response.json(); // Attempt to parse JSON response in all cases
        console.log("response", datas);
        if (response.status === 403 || response.status === 422) {
          setShowError(true);
          setIsSubmitted(false);
          setErrorMessage(datas.message || "Unknown error occurred");
        }
        if (response.status === 200) {
          setIsSubmitted(true);
          setShowError(false); // Show error when request fails
          setErrorMessage(""); // Clear any existing error messages

        // Call to send the event to the parent window
            window.top.postMessage({
              type: 'trackEvent',
              eventName: 'scheduling-success',
              value: 3,
              mode: 'reverse'
          }, '*');

        }

        // Handle successful submission (e.g., navigate to a confirmation page)
      } catch (error) {
        console.error("Error submitting data:", error);
        setIsProcessing(false);
        setIsSubmitted(false);
        // setShowError(true); // Ensure error is shown on catch
        // setErrorMessage(`Error submitting data: ${error}`);
        // Handle submission error (e.g., show an error message)
      } finally {
        setIsProcessing(false);
        // setIsSubmitted(true);
      }
    }
  };

  return (
    <>
      {!isSubmitted && !isProcessing && (
        <form
          className="flex flex-col flex-grow"
          style={{ backgroundColor: "#F2F4F6" }}
        >
          <div className="container pt-6 mx-auto sm:p-6 flex-grow">
            <div className="p-6 bg-color">
              {!goToSchedule && (
                <div className="max-w-screen-md mx-auto space-y-10">
                  {/* Patient Type Display */}
                  <div>
                    <h3 className="w-full text-lg font-bold mb-2">
                      1. Select one
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <fieldset className="sm:col-span-2">
                        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
                          {/* New Patient */}
                          <label className="flex items-center cursor-pointer select-none capitalize">
                            <input
                              type="radio"
                              className="flex-shrink-0 w-5 h-5 border border-gray-300 rounded-full appearance-none cursor-pointer"
                              value="New Patient"
                              checked={selectedOption === "New Patient"}
                              onChange={handleOptionChange}
                              style={{
                                backgroundColor:
                                  selectedOption === "New Patient"
                                    ? "#51B450"
                                    : undefined,
                              }}
                            />
                            <span className="ml-2">New Patient</span>
                          </label>
                          {/* Existing Patient */}
                          <label className="flex items-center cursor-pointer select-none capitalize">
                            <input
                              type="radio"
                              className="flex-shrink-0 w-5 h-5 border border-gray-300 rounded-full appearance-none cursor-pointer"
                              value="Existing Patient"
                              checked={selectedOption === "Existing Patient"}
                              onChange={handleOptionChange}
                              style={{
                                backgroundColor:
                                  selectedOption === "Existing Patient"
                                    ? "#51B450"
                                    : undefined,
                              }}
                            />
                            <span className="ml-2">Existing Patient</span>
                          </label>
                        </div>
                      </fieldset>
                      {/* Booking for */}
                      <fieldset className="sm:col-span-2">
                        <legend className="w-full mb-1 text-sm font-semibold cursor-default">
                          Booking for
                        </legend>
                        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
                          {/* Adult */}
                          <label className="flex items-center cursor-pointer select-none capitalize">
                            <input
                              type="radio"
                              id="adult-patient"
                              name="bookingFor"
                              value="Adult"
                              checked={bookingFor === "Adult"}
                              onChange={handleBookingForChange}
                              className="flex-shrink-0 w-5 h-5 border border-gray-300 rounded-full appearance-none cursor-pointer"
                              style={{
                                backgroundColor:
                                  bookingFor === "Adult"
                                    ? "#51B450"
                                    : undefined,
                              }}
                            />
                            <span className="ml-2">adult <small style={{color: 'blue'}}>(13+)</small></span>
                          </label>
                          {/* Child */}
                          <label className="flex items-center cursor-pointer select-none capitalize">
                            <input
                              type="radio"
                              id="child-patient"
                              name="bookingFor"
                              value="Child"
                              checked={bookingFor === "Child"}
                              onChange={handleBookingForChange}
                              className="flex-shrink-0 w-5 h-5 border border-gray-300 rounded-full appearance-none cursor-pointer"
                              style={{
                                backgroundColor:
                                  bookingFor === "Child"
                                    ? "#51B450"
                                    : undefined,
                              }}
                            />
                            <span className="ml-2">child <small style={{color: 'blue'}}>(12 and under)</small></span>
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  {/* Services Display */}
                  <div>
                    <h3 className="w-full text-lg font-bold mb-2">
                      2. What can we help you with?
                    </h3>
                    <div className="grid col-span-1 gap-4">
                      <ul className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {displayedServices.map((service) => (
                          <li key={service.id} className="flex flex-col">
                            <span
                              className="h-full flex flex-col border rounded p-3 cursor-pointer select-none"
                              onClick={() => handleServiceSelect(service.id)}
                              onMouseEnter={() => handleMouseEnter(service.id)}
                              onMouseLeave={handleMouseLeave}
                              style={{
                                border:
                                  selectedService === service.id
                                    ? "2px solid #51B44F"
                                    : "1px solid #ccc",
                                backgroundColor:
                                  selectedService === service.id ||
                                  hoveredService === service.id
                                    ? "#D4F3BB"
                                    : "transparent",
                              }}
                            >
                              <span className="flex flex-col space-y-2 text-center my-auto">
                                <span className="flex flex-col py-2">
                                  <span className="mx-auto w-10 h-10">
                                    <img
                                      width="40"
                                      src={service.imageUrl}
                                      alt={service.title}
                                      className="max-w-full"
                                    />
                                  </span>
                                </span>
                                <span className="flex flex-col">
                                  <span className="text-sm font-bold break-words line-clamp">
                                    {service.title}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Render the "Choose a day and time" section conditionally */}
                  <Suspense fallback={<div>Loading...</div>}>
                    {
                      <RenderDateTimeSelection
                        // availableSlots={availableSlots}
                        noSlots={noSlots}
                        initialWeekStart={initialWeekStart}
                        selectedService={selectedService}
                        selectedSlots={selectedSlots}
                        locationId={locationId}
                        selectedOption={selectedOption}
                        setSelectedSlots={setSelectedSlots}
                        setSelectedDateSlot={setSelectedDateSlot}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                        setSelectedSlot={setSelectedSlot}
                        setLocId={setLocId}
                        isSlotsLoading={isSlotsLoading}
                        nextWeek={nextWeek}
                        prevWeek={prevWeek}
                        currentWeekStart={currentWeekStart}
                        bookingFor={bookingFor}
                        locID={locID}
                        // underMaintenance={underMaintenance}
                        // locationPhone={locationPhone}
                      />
                    }
                  </Suspense>
                </div>
              )}
              {/* LAZY LOAD Schedule */}
              <Suspense fallback={<div>Loading...</div>}>
                {isNextEnabled && goToSchedule && (
                  <Schedule
                    handleBackClick={handleBackClick}
                    callBackData={handleScheduleCallbackData}
                    showError={showError}
                    errorMessage={errorMessage}
                    selectedOption={selectedOption}
                    bookingFor={bookingFor}
                    selectedService={selectedService}
                    selectedTimeSlot={selectedTimeSlot}
                    selectedDateSlot={selectedDateSlot}
                  />
                )}
              </Suspense>
            </div>
          </div>
          {/* Next Button */}
          {!isScheduleEnabled && (
            <div
              className="z-50 flex-grow py-6 mt-6 bg-color shadow-2xl md:sticky md:bottom-0 md:mt-24"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <div className="container px-6 mx-auto">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                  <button
                    type="button"
                    disabled={!isNextEnabled} // Dynamically enable/disable based on selection
                    className={`text-sm font-bold uppercase border rounded lg:hover:opacity-75 w-full px-10 py-3 lg:w-auto lg:col-start-2 xl:col-start-3 ${
                      isNextEnabled ? "" : "pointer-events-none opacity-50"
                    }`}
                    style={{
                      backgroundColor: isNextEnabled ? "#51B44F" : "#51B44F",
                      color: isNextEnabled ? "#FFFFFF" : "#FFFFFF",
                    }}
                    onClick={handleNextClick}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}

          {isScheduleEnabled && (
            <div
              className="z-50 flex-grow py-6 mt-6 bg-color shadow-2xl md:sticky md:bottom-0 md:mt-24"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <div className="container px-6 mx-auto">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                  <button
                    type="button"
                    disabled={!isSubmissionReady} // Dynamically enable/disable based on selection
                    className={`text-sm font-bold uppercase border rounded lg:hover:opacity-75 w-full px-10 py-3 lg:w-auto lg:col-start-2 xl:col-start-3 ${
                      isSubmissionReady ? "" : "cursor-not-allowed opacity-50"
                    }`}
                    style={{
                      backgroundColor: isSubmissionReady
                        ? "#51B44F"
                        : "#51B44F",
                      color: isSubmissionReady ? "#FFFFFF" : "#FFFFFF",
                    }}
                    onClick={submitAllData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
      )}
      {/* LAZY LOAD Thank You */}
      <Suspense fallback={<div>Loading...</div>}>
        {isSubmitted && !showError && (
          <ThankYou
            locationId={locationId}
            locID={locID}
            selectedDateSlot={selectedDateSlot}
            selectedTimeSlot={selectedTimeSlot}
          />
        )}
      </Suspense>
      {/* Loading component */}
      {isProcessing && !isSubmitted && !showError && (
        <ProcessingComponent
          height={"30vh"}
          msg={"Processing your request, please stand by..."}
        />
      )}
    </>
  );
}

export default Details;
