// src/OnlineBookingContainer.js
import { useState, useEffect } from "preact/hooks";
import Details from "../Details";
import "../App2.css";
import ProcessingComponent from "./ProcessingComponent";

function OnlineBookingContainer() {
  const [locationId, setLocationId] = useState(null);
  const [isNextEnabled, setIsNextEnabled] = useState(false); // Step 1: Define the state
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  // State to handle initial loading

  // Effect hook to read the locationId from URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log(queryParams);
    const locId = queryParams.get("locationId");
    if (locId) {
      setLocationId(locId);
      setIsLoading(false);  // Set loading to false when locationId is found
    } else {
      setIsLoading(false);  // Set loading to false when no locationId is found
    }
  }, []);

  const handleIsNextEnabled = (isEnabled) => {
    setIsNextEnabled(isEnabled); // Step 2: Callback to update state
  };

  const handleIsSubmitEnabled = (isEnabled) => {
    setIsSubmitEnabled(isEnabled);
  };

  return (
    <>
      {isLoading && (
        <ProcessingComponent
          height={"30vh"}
          msg={"Loading, please stand by..."}
        />
      )}
      {locationId && (
        <div
          id="app"
          className="fixed inset-0 flex flex-col min-h-full"
          style={{ backgroundColor: "#F3F4F6", position: "relative" }}
        >
          <div
            className="sticky top-0 z-50 py-2 bg-color shadow-sm text-color"
            style={{ backgroundColor: "#FFFFFF", color: "#212127" }}
          >
            <div className="container grid grid-cols-1 gap-2 px-6 mx-auto sm:grid-cols-3 sm:gap-0">
              {/* <div className="flex items-center sm:col-start-2">
                <h2 className="mx-auto text-lg font-bold">
                  Book an Appointment for {locationId}
                </h2>
              </div> */}
              <div className="hidden md:flex md:items-center">
                <button
                  type="button"
                  className="ml-auto"
                  style="display: none;"
                >
                  <svg
                    role="img"
                    focusable="false"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    width="18"
                    height="18"
                  >
                    <path
                      d="M17 1L1 17M1 1l16 16"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow mt-6">
            <div className="container mx-auto md:px-6">
              <div className="grid md:grid-cols-4">
                <div className="flex justify-center md:col-span-2 md:col-start-2">
                  <a
                    href="#details"
                    className={`flex items-center px-4 text-xs font-bold ${
                      isSubmitEnabled ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    <span
                      className="flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 text-xs rounded-full active"
                      style={{
                        backgroundColor: isSubmitEnabled
                          ? "#D3D3D3"
                          : "#51B44F",
                        color: "#ffffff",
                      }}
                    >
                      1
                    </span>
                    <span className="text-xs text-color">Details</span>
                  </a>
                  <a
                    href="#schedule"
                    className={`flex items-center px-4 text-xs font-bold ${
                      isSubmitEnabled ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    <span
                      className="flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 text-xs rounded-full"
                      style={{
                        backgroundColor: isSubmitEnabled
                          ? "#D3D3D3"
                          : isNextEnabled
                          ? "#51B44F"
                          : "#D3D3D3",
                        color: "#ffffff",
                      }}
                    >
                      2
                    </span>
                    <span className="text-xs text-color">Schedule</span>
                  </a>
                </div>
                <div className="hidden col-span-1 md:flex">
                  <a
                    href="tel:833-879-6468"
                    target="_self"
                    className="text-sm font-bold uppercase border border-gray-300 rounded truncate flex items-center justify-center px-4 py-2 ml-auto border-gray-500"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "#212127",
                      color: "#212127",
                    }}
                  >
                    <svg
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="mr-1 flex-shrink-0"
                    >
                      <path
                        d="M19.5 21.75h-.127C4.635 20.902 2.543 8.467 2.25 4.672A2.25 2.25 0 014.32 2.25h4.133a1.5 1.5 0 011.395.945L10.988 6a1.5 1.5 0 01-.33 1.62L9.06 9.232a7.027 7.027 0 005.685 5.7l1.628-1.612A1.499 1.499 0 0118 13.012l2.828 1.133a1.5 1.5 0 01.922 1.395v3.96a2.25 2.25 0 01-2.25 2.25zm-15-18a.75.75 0 00-.75.75v.06C4.095 9 6.308 19.5 19.455 20.25a.749.749 0 00.795-.705V15.54l-2.827-1.132-2.153 2.137-.36-.045C8.385 15.682 7.5 9.158 7.5 9.09l-.045-.36 2.13-2.153L8.46 3.75H4.5z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <span className="text-sm font-bold uppercase">
                      {" "}
                      833-879-6468{" "}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <Details
              // onSelectPatientType={onSelectPatientType}
              locationId={locationId}
              onIsNextEnabled={handleIsNextEnabled}
              isSubmitEnabled={handleIsSubmitEnabled}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default OnlineBookingContainer;
