import { useState } from 'preact/hooks';

// Custom hook for handling hover state
function useHover() {
  const [hoveredService, setHoveredService] = useState(null);

  // Function to call when mouse enters an item
  const handleMouseEnter = (itemId) => {
    setHoveredService(itemId);
  };

  // Function to call when mouse leaves an item
  const handleMouseLeave = () => {
    setHoveredService(null);
  };

  return { hoveredService, handleMouseEnter, handleMouseLeave };
}

export default useHover;
